import { DoubleSide, BufferGeometry, Mesh, MeshBasicMaterial, Triangle, Vector3, ColorRepresentation } from "three";
import { geometryToVectors, vectorsToPosition } from "viewer/utils/convert";
import { triangulate } from "viewer/utils/triangulation";
import { dispose } from "viewer/utils/mesh";
import { scene } from "viewer/core";

const DEFAULT_COLOR = "#ffff00";
const DEFAULT_OPACITY = 0.2;

const DEFAULT_WF_COLOR = "#ffff00";
const DEFAULT_WF_OPACITY = 0.2;

export class Polygon {
  private readonly mesh: Mesh;
  private readonly clip: Mesh;
  private readonly wireframe: Mesh;

  constructor(visible = true, color: ColorRepresentation = DEFAULT_COLOR, opacity: number = DEFAULT_OPACITY) {
    this.mesh = Polygon.createMesh(color, opacity);
    this.clip = Polygon.createMesh(color, opacity, true);
    this.wireframe = Polygon.createMesh(DEFAULT_WF_COLOR, DEFAULT_WF_OPACITY, false, true);

    if (!visible) this.hide();
  }

  public get vertices(): Vector3[] {
    return geometryToVectors(this.mesh.geometry);
  }

  /** Sets polygon as visible */
  public show(): void {
    this.mesh.visible = true;
    this.clip.visible = true;
    this.wireframe.visible = true;
  }

  /** Sets polygon as hidden */
  public hide(): void {
    this.mesh.visible = false;
    this.clip.visible = false;
    this.wireframe.visible = false;
  }

  public get visible(): boolean {
    return this.mesh.visible;
  }

  /** Recreates polygon from array of vectors */
  public update(vectors: Vector3[]): Triangle[] {
    if (vectors.length < 3) {
      this.hide();
      return [];
    }

    this.mesh.geometry.dispose();
    this.clip.geometry.dispose();
    this.wireframe.geometry.dispose();

    const { flatTriangles, triangles } = triangulate(vectors);

    if (!triangles.length) {
      this.hide();
      return [];
    }

    const geometry = new BufferGeometry();
    geometry.setIndex(flatTriangles);
    geometry.setAttribute("position", vectorsToPosition(vectors));

    this.mesh.geometry = geometry;
    this.clip.geometry = geometry;
    this.wireframe.geometry = geometry;

    this.show();

    return triangles;
  }

  /** Resets polygon geometry and hides it */
  public clear(): void {
    this.hide();
    dispose(this.mesh);
    dispose(this.clip);
    dispose(this.wireframe);
  }

  /** Creates new polygon mesh instance */
  private static createMesh(color: ColorRepresentation, opacity: number, depthTest = false, wireframe = false): Mesh {
    const geometry = new BufferGeometry();
    const material = new MeshBasicMaterial({
      color: color,
      opacity: opacity,
      wireframe: wireframe,
      depthTest: depthTest,
      transparent: true,
      side: DoubleSide,
    });

    const mesh = new Mesh(geometry, material);

    if (wireframe) mesh.translateY(0.00001);

    mesh.matrixAutoUpdate = false;

    scene.add(mesh);

    return mesh;
  }

  public cleanup(): void {
    Polygon.dispose(this.mesh);
    Polygon.dispose(this.clip);
    Polygon.dispose(this.wireframe);
  }

  private static dispose(mesh: Mesh): void {
    scene.remove(mesh);
    dispose(mesh);
  }
}
