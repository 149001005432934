import {
  BufferAttribute,
  BufferGeometry,
  ColorRepresentation,
  Float32BufferAttribute,
  Group,
  Line,
  LineBasicMaterial,
  Vector3,
} from "three";
import { scene } from "viewer/core";
import { getVectorsOnModel } from "viewer/utils";
import { RealProjected } from "viewer/measurement/interface";
import { MeasurementValues } from "viewer/measurement/Values";

const DEFAULT_COLOR = "#ffff00";
const DEFAULT_COLOR_INVALID = "#ff0000";
const DEFAULT_LENGTH: RealProjected = { real: 0, projected: 0 };

export class CursorLine {
  private readonly line: Line;
  private readonly lineMaterial: LineBasicMaterial;
  private readonly lineMaterialInvalid: LineBasicMaterial;

  private readonly color: ColorRepresentation;
  private readonly colorInvalid: ColorRepresentation;

  private model?: Group;
  private raycast = false;

  public length: RealProjected = DEFAULT_LENGTH;

  constructor(
    visible = true,
    color: ColorRepresentation = DEFAULT_COLOR,
    colorInvalid: ColorRepresentation = DEFAULT_COLOR_INVALID,
  ) {
    this.color = color;
    this.colorInvalid = colorInvalid;

    this.lineMaterial = CursorLine.createMaterial(color);
    this.lineMaterialInvalid = CursorLine.createMaterial(colorInvalid);

    this.line = CursorLine.create(this.lineMaterial);
    this.line.visible = visible;

    scene.add(this.line);
  }

  public setModel(model: Group): void {
    this.model = model;
  }

  public setRaycast(raycast: boolean): void {
    this.raycast = raycast;
  }

  /** Redraws lines geometry by given vectors - returns whether the line is valid */
  public update(start: Vector3, end: Vector3, firstVector?: Vector3): boolean {
    // Cursor for non-volume measurement
    if (!this.raycast || !this.model) {
      this.updateGeometry([start.clone(), end.clone()]);
      return true;
    }

    // Cursor for volume measurement
    const points = getVectorsOnModel(start, end, this.model);

    const polygonCloseValid = firstVector ? !!getVectorsOnModel(end, firstVector, this.model)?.length : true;

    // Valid line
    if (points && points.length && polygonCloseValid) {
      this.updateGeometry(points);
      return true;
    }

    // Invalid line (with holes)
    this.updateGeometry([start.clone(), end.clone()], true);
    return false;
  }

  /** Sets line as visible */
  public show(): void {
    this.line.visible = true;
  }

  /** Sets line as hidden */
  public hide(): void {
    this.length = DEFAULT_LENGTH;
    this.line.visible = false;
  }

  /** Removes line from scene and disposes its geometry and material */
  public dispose(): void {
    scene.remove(this.line);
    this.line.geometry.dispose();
    this.lineMaterial.dispose();
    this.lineMaterialInvalid.dispose();
  }

  /** Creates a new line instance */
  private static create(material: LineBasicMaterial): Line {
    const geometry = new BufferGeometry();
    geometry.setDrawRange(0, 2);

    const positions = new Float32Array(500 * 3); // 3 = vertices per point, 500 = allocated vertices per line
    geometry.setAttribute("position", new BufferAttribute(positions, 3));

    return new Line(geometry, material);
  }

  /** Creates a line material */
  private static createMaterial(color: ColorRepresentation): LineBasicMaterial {
    return new LineBasicMaterial({ color, depthTest: false });
  }

  /** Updates cursor material */
  private updateMaterial(invalid = false): void {
    this.lineMaterial.dispose();
    this.lineMaterialInvalid.dispose();
    this.line.material = invalid ? CursorLine.createMaterial(this.colorInvalid) : CursorLine.createMaterial(this.color);
  }

  /** Updates geometry by given vectors */
  private updateGeometry(points: Vector3[], invalid = false): void {
    this.updateMaterial(invalid);

    this.line.geometry.setFromPoints(points);
    this.geometry.setDrawRange(0, points.length);
    this.position.needsUpdate = true;
    this.line.geometry.computeBoundingSphere();
    this.length = MeasurementValues.calculateDistanceFromArray(points);
  }

  /** Returns lines geometry */
  private get geometry(): BufferGeometry {
    return this.line.geometry;
  }

  /** Returns lines geometry position attribute */
  private get position(): Float32BufferAttribute {
    return this.line.geometry.attributes.position as Float32BufferAttribute;
  }
}
